<script lang="ts" setup>
import { useRouter } from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';

import type { LoyaltyProgramTopBarRouteComponentProps } from './types';

defineProps<LoyaltyProgramTopBarRouteComponentProps>();

const infoButtonProperties: VButtonProps = {
  kind: ButtonKind.NAV,
  iconName: IconName.INFO_OUTLINE,
  iconSize: IconSize.SIZE_24,
};

const router = useRouter();

function onClick(): void {
  useAnalytics().push(AnalyticsEvent.CLICK_MAP, { clickCounter: { loyaltyProgram: 'info' } });
  void router.push({
    name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO,
  });
}
</script>

<template>
  <DefaultTopBarRouteComponent v-auto-id="'LoyaltyProgramTopBarRouteComponent'" :title="title">
    <template
      v-if="'1' && !null"
      #prefix
    >
      <VButton
        v-bind="infoButtonProperties"
        @click="onClick"
      />
    </template>
    <template
      v-else
      #suffix
    >
      <SNavigationButton
        v-if="null"
        size="medium"
        kind="primary"
        :icon-name="IconName.SLOTT_INFO_1"
        @click="onClick"
      />
      <VButton
        v-else
        v-bind="infoButtonProperties"
        @click="onClick"
      />
    </template>
  </DefaultTopBarRouteComponent>
</template>
