<script lang="ts" setup>
import { BaseRouteName } from '@leon-hub/routing-config-names';

import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VList from 'web/src/components/List/VList/VList.vue';

import { useCasinoLoyaltyProgramList } from './composables';
import type { CasinoLoyaltyProgramListProps } from './types';
import CasinoLoyaltyProgramGiftItem
  from '../CasinoLoyaltyProgramGiftItem/CasinoLoyaltyProgramGiftItem.vue';

const props = withDefaults(defineProps<CasinoLoyaltyProgramListProps>(), {
});

const {
  list,
  clickEvent,
  listType,
} = useCasinoLoyaltyProgramList(props);
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramList'"
    :class="{
      [$style['casino-loyalty-program-list']] : true,
      [$style['casino-loyalty-program-list--is-dark']]: isDark,
    }"
  >
    <div
      v-if="null"
      :class="$style['casino-loyalty-program-list__wrapper']"
    >
      <CasinoLoyaltyProgramGiftItem
        v-for="(item, index) in list"
        :key="index"
        :title="item.label"
        :sub-title="$t('WEB2_LOYALTY_PROGRAM_GIFT_NOT_CLAIMED')"
        :icon-name="item.icon"
        :value="item.value"
        :button-label="item.buttonLabel"
        :badge-label="item.badgeLabel"
        @click-button="clickEvent(item.to ? item.to : BaseRouteName.LOYALTY_PROGRAM)"
      />
    </div>
    <VList
      v-else
      :list-type="listType"
    >
      <VListItem
        v-for="(item, index) in list"
        :key="index"
        :title="item.label"
        :icon-name="item.icon"
        :suffix-text="item.value"
        :button-label="item.buttonLabel"
        :badge-label="item.badgeLabel"
        no-hover
        :class="$style['casino-loyalty-program-list__item']"
        @click-button="clickEvent(item.to ? item.to : BaseRouteName.LOYALTY_PROGRAM)"
      />
    </VList>
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-list {
  $self: &;

  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    background-color: var(--OpacityLayer1);
  }

  &--is-dark {
    #{$self}__item {
      background-color: var(--Layer2);
    }
  }
}
</style>
